@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

:root {
  --primary-color: #033a79;
  --error-color: #FF4444;
  --warning-color: #E8B407;
  --ok-color: #66CDAA;

  --background-color: #fff;
  --bg-color-secondary: #F8F9FA;
  --font-color: #242424;
  --chart-font-color: #3d3d3d;
  --line-border-color: rgba(0, 0, 0, .12);
  --icon-color: #585858;
  --hover-icon-color: rgba(0, 0, 0, .1);
}

[data-theme="dark"] {
  --background-color: #212529;
  --bg-color-secondary: #2B3035;
  --font-color: #fff;
  --chart-font-color: #fff;
  --line-border-color: rgba(103, 112, 121, 0.4);
  --icon-color: #AAADB1;
  --hover-icon-color: rgba(255, 255, 255, .1);
}

body {
  font-family: "Ubuntu Sans", sans-serif;
  background-color: #e8e8e8;
  padding: 0;
  margin: 0;
}

input,
div,
input::placeholder,
textarea::placeholder {
  font-family: "Ubuntu Sans", sans-serif;
}

input:focus,
textarea:focus {
  outline: none;
}

a,
nav button {
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: #000;
  transition: .4s;
  font-size: 1em;
  cursor: pointer;
  font-family: "Ubuntu Sans", sans-serif;
}

a:hover,
nav button:hover {
  color: #bbb;
}

.apexcharts-yaxis-label,
.apexcharts-xaxis-label,
.apexcharts-title-text,
.apexcharts-menu-icon svg,
.apexcharts-reset-icon svg,
.apexcharts-zoom-icon svg,
.apexcharts-zoomin-icon svg,
.apexcharts-zoomout-icon svg {
  fill: var(--chart-font-color) !important;
}

.apexcharts-legend-text {
  color: var(--chart-font-color) !important;
}